import API from '@/api'
import DocumentTypesResponse from './models/DocumentTypesResponse'

const executeAction = async (): Promise<DocumentTypesResponse> => {
  try {
    const response = await API.DropdownsRepository.getDocumentTypes()
    return new DocumentTypesResponse(response.data)
  } catch (err) {
    console.error('[getStates] - Ha ocurrido un error al obtener getDocumentTypes')
    throw err
  }
}

export default executeAction
