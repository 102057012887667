<template>
  <div v-if="showTopMenu" class="menu-complete">
    <f-top-app-bar-mobile
      v-if="isMobile"
      navigation-icon=""
      style="background-color: #fff;x"
      color="secondary"
    >
      <f-icon
        slot="right"
        class="logout-item"
        name="sign-out-alt"
        @click="onLogout"
      />
      <img slot="left"
           :src="logo"
           alt="logo"
           style="height: 33.6px; margin-top: 5px"
      >
    </f-top-app-bar-mobile>
    <f-top-app-bar
      v-else
      class="top-bar" title="Firma Digital"
      :img="logo"
      navigation-icon=""
      :navigation-icon-always="false"
      @clickNavigationIcon="openDrawer"
    >
      <template slot="left">
        <img slot="left" :src="logo"
             alt="logo"
             style="cursor:pointer;"
             @click="toHome()"
        >
        <f-top-app-bar-separator />
        <span class="desktop-title">Plataforma Firma Digital</span>
      </template>
      <template slot="right">
        <f-button text @click="onInfoLegal">
          Políticas de Privacidad
        </f-button>
        <f-top-app-bar-separator />
        <f-menu direction="bottom-end"
                :items-menu="itemsMenuManuales"
        >
          <f-icon slot="activator" name="question-circle" size="lg" />
        </f-menu>
        <f-top-app-bar-separator />
        <f-menu direction="bottom-end">
          <f-button slot="activator" text
                    icon="user" trailicon="angle-down"
          >
            {{ username }}
          </f-button>
          <f-list-item v-if="isUsuarioExterno" text="Cambiar contraseña" @click="onPassChange" />
          <f-list-item text="Cerrar sesión" @click="onLogout" />
        </f-menu>
      </template>
      <!-- <template slot="rightMobile">
        <f-icon class="logout-item" name="sign-out-alt" @click="onLogout" />
      </template>
      -->
    </f-top-app-bar>
    <f-menu-horizontal
      v-if="!isMobile"
      ref="menuHorizontalRef"
      class="f-menu-horizontal"
      fixed
      :items-menu="itemsMenu"
      :open-drawer="openDrawerData"
      @closeDrawer="closeDrawer"
      @resize="onResize"
    />
  </div>
</template>

<script>
import { FTopAppBar, FTopAppBarMobile, FTopAppBarSeparator, FMenuHorizontal, FMenu, FButton, FIcon, FListItem } from 'fwkc4-vue'
import InfoLegal from './dialogs/infoLegal/InfoLegal.vue'
import UserChangePasswordDialog from './dialogs/users/UserChangePasswordDialog.vue'
import logoHorizontal from '../assets/logo_horizontal.png'
import { mapGetters } from 'vuex'
import { isMobile } from '@/utils/utils'
import env from '@/services/environment'

export default {
  name: 'MenuComplete',
  components: {
    FTopAppBar,
    FTopAppBarMobile,
    FTopAppBarSeparator,
    FMenuHorizontal,
    FButton,
    FIcon,
    FMenu,
    FListItem
  },
  props: {
    username: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      logo: logoHorizontal,
      openDrawerData: false
    }
  },
  computed: {
    ...mapGetters('user', [
      'isAdmin',
      'isUsuarioExterno',
      'isSindicato',
      'isAdministradorUsuarios',
      'isADCO',
      'isResponsableRRHH',
      'getUserAllowedCenters',
      'getProfiles'
    ]),
    isMobile () {
      return isMobile()
    },
    itemsMenu () {
      const items = []
      if (this.isAdmin) {
        items.unshift({
          name: 'Consulta de Documentos',
          items: [{
            name: 'Pendientes',
            action: () => this.sendTabEvent('Pendientes')
          }, {
            name: 'Todos',
            action: () => this.sendTabEvent('Todos')
          }, {
            name: 'Gestión de Documentación',
            action: () => this.sendTabEvent('GestionDocumentacion')
          }]
        }, {
          name: 'Mantenimiento',
          items: [{
            name: 'Usuarios Externos',
            action: () => this.sendTabEvent('UsuariosExternos')
          }, {
            name: 'Flujos',
            action: () => this.sendTabEvent('Flujos')
          }]
        })
      } else if (this.isSindicato) {
        items.unshift({
          name: 'Consulta de Documentos',
          items: [{
            name: 'Todos',
            action: () => this.sendTabEvent('Todos')
          }]
        })
      } else if (this.isAdministradorUsuarios) {
        items.push({
          name: 'Mantenimiento',
          items: [{
            name: 'Usuarios Externos',
            action: () => this.sendTabEvent('UsuariosExternos')
          }]
        })
        if (this.getProfiles.length > 1) {
          items.unshift({
            name: 'Consulta de Documentos',
            items: [{
              name: 'Pendientes',
              action: () => this.sendTabEvent('Pendientes')
            }, {
              name: 'Todos',
              action: () => this.sendTabEvent('Todos')
            }]
          })
        }
      } else if (this.isResponsableRRHH) {
        items.push({
          name: 'Consulta de Documentos',
          items: [{
            name: 'Pendientes',
            action: () => this.sendTabEvent('Pendientes')
          }, {
            name: 'Todos',
            action: () => this.sendTabEvent('Todos')
          }]
        })
        if (this.getUserAllowedCenters === 'all') {
          items[0].items.push({
            name: 'Gestión de Documentación',
            action: () => this.sendTabEvent('GestionDocumentacion')
          })
        }
      } else if (!this.isAdministradorUsuarios) {
        items.unshift({
          name: 'Consulta de Documentos',
          items: [{
            name: 'Pendientes',
            action: () => this.sendTabEvent('Pendientes')
          }, {
            name: 'Todos',
            action: () => this.sendTabEvent('Todos')
          }]
        })
      }
      return items
    },
    showTopMenu () {
      return this.$store.getters['ui/showTopMenu']
    },
    itemsMenuManuales () {
      if (this.isResponsableRRHH) {
        return [
          {
            name: 'Manual de usuario',
            action: () => this.navigateTo('VUE_APP_MANUAL_UPFI0006')
          }
        ]
      } else if (this.isSindicato) {
        return [
          {
            name: 'Manual de usuario',
            action: () => this.navigateTo('VUE_APP_MANUAL_UPFI0005')
          }
        ]
      } else if (this.isUsuarioExterno) {
        return [
          {
            name: 'Manual de usuario',
            action: () => this.navigateTo('VUE_APP_MANUAL_UPFI0004')
          },
          {
            name: 'Guía primeros pasos',
            action: () => this.navigateTo('VUE_APP_GUIA_UPFI0004')
          }
        ]
      } else {
        return [
          {
            name: 'Manual de usuario',
            action: () => this.navigateTo('VUE_APP_MANUAL_RESTO')
          },
          {
            name: 'Guía primeros pasos',
            action: () => this.navigateTo('VUE_APP_GUIA_RESTO')
          }
        ]
      }
    }
  },
  methods: {
    navigateTo (route) {
      const urlBase = `${env.getEnvVariable(route)}`
      window.open(urlBase, '_blank')
    },
    openDrawer () {
      this.openDrawerData = true
    },
    closeDrawer () {
      this.openDrawerData = false
    },
    onPassChange () {
      this.$store.dispatch('ui/openDialog', {
        component: UserChangePasswordDialog
      })
    },
    onInfoLegal () {
      this.$store.dispatch('ui/openDialog', {
        component: InfoLegal
      })
    },
    onLogout () {
      this.$emit('logout')
    },
    onResize (event) {
      this.$emit('resize', event)
    },
    toHome () {
      this.$router.push('/').catch((err) => { return err })
    },
    sendTabEvent (tabName) {
      const event = new CustomEvent('eventOpenTab', {
        detail: {
          tabName: tabName
        }
      })
      document.dispatchEvent(event)
    }
  }
}
</script>

<style lang="scss">
.menu-complete {
  z-index:3;
  .logout-item {
    font-size: 35px !important;
    margin-right: 5px;
  }
  .f-menu-horizontal {
    margin-top: 50px;
    z-index:3 !important;
    .menu-desktop__fixed {
      z-index:3 !important;
      .menu-desktop__left,
      .menu-desktop__right {
        z-index:3 !important;
      }
    }
  }
  .desktop-title {
    margin-left: 5px;
    font-size: 20px;
    font-weight: 600;
    color: var(--f-color-primary);
    text-transform: none;
  }
  .f-top-app-bar-mobile .mdc-top-app-bar__row {
    --f-top-app-bar-mobile__height: 46.9px;
    height: var(--f-top-app-bar-mobile__height);
  }
// }
// .top-bar {
//   border-bottom: 1px solid var(--f-color-grey-4);
//   &.mobile {
//     .mdc-top-app-bar__section--align-start {
//       display: none;
//     }
//     .mdc-top-app-bar__section--align-center-absolute {
//       justify-content: flex-start;
//       padding-left: 10px;
//     }
//   }
}
</style>
