import { xhr } from '@/api/config/Repository'
import { AxiosResponse } from 'axios'
import env from '@/services/environment'
import store from '@/store'
import RrhhSearchRequestDTO from '../dto/RrhhSearchRequestDTO'
import RrhhSearchResponseDTO from '../dto/RrhhSearchResponseDTO'
import RrhhRepository from '../RrhhRepository'
import RrhhUpdateMatriculaRequestDTO from '../dto/RrhhUpdateMatriculaRequestDTO'
import GenericResponseDTO from '@/api/common/GenericResponseDTO'
import RrhhDownloadResponseDTO from '../dto/RrhhDownloadResponseDTO'
import RrhhDownloadDocumentacionRequestDTO from '../dto/RrhhDownloadDocumentacionRequestDTO'
import RrhhDownloadDocumentacionResponseDTO from '../dto/RrhhDownloadDocumentacionResponseDTO'

export default class RrhhRepositoryImpl implements RrhhRepository {
  getDefaultHeaders (): any {
    return {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      version: env.getEnvVariable('VUE_APP_RELEASE_VERSION_TAG'),
      username: store.getters['user/getUsername']
    }
  }

  rrhhSearch (params: RrhhSearchRequestDTO): Promise<AxiosResponse<RrhhSearchResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/rrhh/search'
    const headers = this.getDefaultHeaders()
    return xhr.post(msPFDUrl.concat(endpoint), params, { headers })
  }

  rrhhUpdateMatricula (id: number, params: RrhhUpdateMatriculaRequestDTO): Promise<AxiosResponse<RrhhSearchResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = `/ui/rrhh/document/${id}`
    const headers = this.getDefaultHeaders()
    return xhr.patch(msPFDUrl.concat(endpoint), params, { headers })
  }

  rrhhDelete (id: number): Promise<AxiosResponse<GenericResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = `/ui/rrhh/document/${id}`
    const headers = this.getDefaultHeaders()
    return xhr.delete(msPFDUrl.concat(endpoint), { headers })
  }

  rrhhDownload (id: number): Promise<AxiosResponse<RrhhDownloadResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = `/ui/rrhh/document/${id}/download`
    const headers = this.getDefaultHeaders()
    return xhr.get(msPFDUrl.concat(endpoint), { headers })
  }

  rrhhDownloadDocumentacion (params: RrhhDownloadDocumentacionRequestDTO): Promise<AxiosResponse<RrhhDownloadDocumentacionResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/rrhh/downloadDocumentation'
    const headers = this.getDefaultHeaders()
    return xhr.post(msPFDUrl.concat(endpoint), params, { headers })
  }
}
