import { xhr } from '@/api/config/Repository'
import { AxiosResponse } from 'axios'
import DepartmentsResponseDTO from '../dto/DepartmentsResponseDTO'
import FlowsResponseDTO from '../dto/FlowsResponseDTO'
import ViewsResponseDTO from '../dto/ViewsResponseDTO'
import StatesResponseDTO from '../dto/StatesResponseDTO'
import StepsRequestDTO from '../dto/StepsRequestDTO'
import StepsResponseDTO from '../dto/StepsResponseDTO'
import DropdownsRepository from '../DropdownsRepository'
import env from '@/services/environment'
import store from '@/store'
import DocumentTypesResponseDTO from '../dto/DocumentTypesResponseDTO'

export default class DropdownsRepositoryImpl implements DropdownsRepository {
  getDefaultHeaders (): any {
    return {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      version: env.getEnvVariable('VUE_APP_RELEASE_VERSION_TAG'),
      username: store.getters['user/getUsername']
    }
  }

  getFlows (): Promise<AxiosResponse<FlowsResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/dropdowns/flows'
    const headers = this.getDefaultHeaders()
    return xhr.get(msPFDUrl.concat(endpoint), { headers })
  }

  getViews (): Promise<AxiosResponse<ViewsResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/dropdowns/views'
    const headers = this.getDefaultHeaders()
    return xhr.get(msPFDUrl.concat(endpoint), { headers })
  }

  getStates (): Promise<AxiosResponse<StatesResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/dropdowns/states'
    const headers = this.getDefaultHeaders()
    return xhr.get(msPFDUrl.concat(endpoint), { headers })
  }

  getSteps (request: StepsRequestDTO): Promise<AxiosResponse<StepsResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    let endpoint = '/ui/dropdowns/steps'
    if (request.flowId > 0) endpoint += '?flowId=' + request.flowId
    const headers = this.getDefaultHeaders()
    return xhr.get(msPFDUrl.concat(endpoint), { headers })
  }

  getDepartments (): Promise<AxiosResponse<DepartmentsResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/dropdowns/departments'
    const headers = this.getDefaultHeaders()
    return xhr.get(msPFDUrl.concat(endpoint), { headers })
  }

  getDocumentTypes (): Promise<AxiosResponse<DocumentTypesResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/dropdowns/documentTypes'
    const headers = this.getDefaultHeaders()
    return xhr.get(msPFDUrl.concat(endpoint), { headers })
  }
}
