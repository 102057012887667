import DocumentTypeDTO from '@/api/common/DocumentTypeDTO'
import getDocumentTypes from '@/services/dropdowns/getDocumentTypes.service'
import store from '@/store'

const executeAction = async (): Promise<DocumentTypeDTO[]> => {
  try {
    const response = await getDocumentTypes()
    // pasamos los datos a la store de combos
    if (response != null) {
      const data = {
        valores: response,
        tipo: 'DOCUMENT_TYPES'
      }
      store.dispatch('combos/updateCombo', { data })
      return response.fields
    }
  } catch (err) {
    console.error('[getStates] - Ha ocurrido un error al llamar a getDocumentTypes')
    throw err
  }
}

export default executeAction
