
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { FButton } from 'fwkc4-vue'
import GenericErrorDialog from '@/views/common/dialogs/GenericErrorDialog.vue'
import DocumentData from './DocumentData.vue'
import Timeline from '@/components/timeline/Timeline.vue'
import getTransactionSteps from '@/services/transactions/getTransactionSteps.management'

export default Vue.extend({
  name: 'Historico',
  components: {
    Timeline,
    DocumentData,
    FButton
  },
  props: {
    showButtons: {
      type: Boolean,
      default: false
    },
    hasChanges: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      timeline: null,
      transaction: null
    }
  },
  computed: {
    ...mapGetters('transactionDetail', [
      'getCurrentTransaction'
    ]),
    getTimeline () {
      return this.timeline
    },
    texareaClass () {
      return this.showButtons ? 'buttons' : 'no-buttons'
    }
  },
  mounted () {
    this.bindData()
  },
  methods: {
    cancelChanges () {
      this.$emit('onCancelChanges')
    },
    confirmSaveChanges () {
      this.$emit('onConfirmSaveChanges')
    },
    async bindData () {
      this.transaction = this.getCurrentTransaction
      let response = null
      try {
        const searchParams = {
          id: this.transaction.id,
          history: (this.transaction.historico === true) ? 'yes' : null,
          year: this.transaction.anyo
        }
        response = await getTransactionSteps(searchParams)
        if (response?.steps?.length > 0) {
          const data = response.steps
          this.addExtraStepInfo(data)
          this.timeline = data
        }
      } catch (err) {
        console.log(err)
        this.$store.dispatch('ui/openDialog', {
          component: GenericErrorDialog,
          props: {
            text: 'Se ha producido un error, inténtalo de nuevo más tarde'
          }
        })
      } finally {
        this.$store.dispatch('ui/hideMask')
      }
    },
    addExtraStepInfo (data) {
      data.forEach((step) => {
        if (step.estado_paso === 'FINALIZED') {
          step.estado_paso = 'FINALIZADO'
          step.icon = 'check'
        } else if (step.estado_paso === 'PENDING') {
          step.estado_paso = 'PENDIENTE'
          step.icon = 'user'
          // step.action_needed = 'pulse_wrap'
        } else if (step.estado_paso === 'REGISTERED') {
          step.estado_paso = 'REGISTRADO'
          step.icon = 'check'
        } else if (step.estado_paso === 'REJECTED') {
          step.estado_paso = 'RECHAZADO'
          step.icon = 'ban'
        } else if (step.estado_paso === 'CANCELLED') {
          step.estado_paso = 'CANCELADO'
          step.icon = 'times'
        }
      })
      const begin: any = {
        paso: 'Inicio proceso',
        estado_paso: 'INICIO',
        icon: '',
        fecha_firma: this.transaction.fecalta
      }
      data.unshift(begin)
      if (this.transaction.estado.estadoInterno !== 'PENDIENTE') {
        const end: any = {
          paso: 'Fin proceso',
          estado_paso: 'TERMINADO',
          icon: '',
          fecha_firma: this.transaction.fecha_modificacion
        }
        if (this.transaction.estado.estadoInterno === 'FIRMADO') {
          end.estado_paso = 'FIN'
          end.fecha_firma = this.transaction.fecha_firma
        }
        data.push(end)
      }
    }
  }
})
