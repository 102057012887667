
import Vue from 'vue'
import BasicDialog from '@/components/dialogs/BasicDialog.vue'
import MobileDialog from '@/components/dialogs/MobileDialog.vue'
import { FButton } from 'fwkc4-vue'
import { isMobile } from '@/utils/utils'

export default Vue.extend({
  name: 'UserLogoutDialog',
  components: {
    BasicDialog,
    MobileDialog,
    FButton
  },
  props: {
    functionCb: {
      type: Function,
      default: null
    }
  },
  methods: {
    isMobile () {
      return isMobile()
    },
    onCancel () {
      this.$emit('closed')
    },
    onLogout () {
      document.dispatchEvent(new Event('logoutapp'))
      this.onCloseDialog()
    },
    onCloseDialog () {
      this.$emit('closed')
    }
  }
})
